@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Italianno&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Handlee&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@400;500;600;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Gothic A1', sans-serif;
}

.btn {
  border: none;
  box-shadow: none;
  outline: none;
}

.btn:focus, .btn:active {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: #fb2300;
}

.Navbar {
  box-shadow: 0px 9px 10px -15px #000000;
  justify-content: center !important;
  background-color: white;
}

.hamburger {
  font-size: 1.4rem;
  cursor: pointer;
}

.outerMenuBar {
  position: fixed;
  height: 100vh;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.menuBar {
  padding: 3rem 4rem;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  background-color: white;
  box-shadow: 0 0 20px rgb(118, 118, 118);
}

.menuBarCrossDiv {
  text-align: right;
}

.menuBarCross {
  font-size: 2.5rem;
  cursor: pointer;
}

.registerBtn {
  background-color: #fb2300;
  letter-spacing: 0.2rem;
  padding: 1rem 2rem;
  border-radius: 30px;
}

.registerBtn:hover {
  background-color: #d92206;
}

.signInBtn {
  background-color: white;
  color: #000000;
  letter-spacing: 0.2rem;
  padding: 1rem 3rem;
  border-radius: 30px;
}

.signInBtn:hover {
  background-color: white;
  border: 1px solid black;
  color: #000000;
}

.logoutBtn {
  color: #606060;
  letter-spacing: 0.2rem;
  cursor: pointer;
}

.logoutBtn:hover {
  color: #414141;
}

.nameText {
  font-size: 1.3rem;
  color: #5D5C5C;
}

.emailText {
  font-size: 1rem;
  color: #606060;
}

.mainHeading {
  color: #fb2300;
  font-weight: bolder;
}

.mainHeading2 {
  font-size: 2rem;
  color: #5D5C5C;
  font-family: 'Handlee', cursive;
}

.middleText {
  font-size: 1.7rem;
}

.accordText {
  line-height: 2rem;
}

.points {
  margin-top: 5rem;
  padding: 2.5rem;
  background-color: #ffffff;
}

ul {
  list-style: circle;
  font-size: 1.1rem;
  letter-spacing: 1px;
  line-height: 2.5rem;
  font-weight: 400;
  color: #000000;
}

.mainBtn {
  font-size: 1rem;
  padding: .8rem 3rem;
  font-weight: 700;
  color: white;
  letter-spacing: 4px;
  border-radius: 5rem;
  background-color: #fb2300;
  transition: all ease-in-out .3s;
  line-height: 2rem;
}

.signInGoogle {
  font-size: 1rem;
  cursor: pointer;
  padding: .8rem 0rem;
  margin: 2rem 3rem 3.5rem 3rem;
  font-weight: 600;
  color: #5D5C5C;
  border-radius: 5rem;
  background-color: #efefef;
  width: auto;
  letter-spacing: 2px;
}

.mainBtn:hover {
  background-color: #cf2207;
  transform: scale(0.98)
}

.footerBg {
  padding: 2rem 0;
  background-color: #f1f3f4;
}

.footerText {
  font-size: 1.3rem;
  letter-spacing: 2px;
  color: #7e7e7e;
  margin-bottom: 1rem;
}

.footerText2 {
  font-size: 1.1rem;
  letter-spacing: 2.2px;
  color: #7e7e7e;
  margin-top: 2rem;
}

.footerLogo {
  width: 75%;
  cursor: pointer;
}

.footerIcon{
  width: 75%;
}

.outerDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98.06vw;
  height: 100vh;
  position: fixed;
  padding: 1rem;
  top: 0;
  right: 0;
}

.higherDiv {
  background-color: rgba(0, 0, 0, 0.8);
}

.innerDiv {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 31px;
  box-shadow: 0 0 5px rgb(197, 196, 196);
}

.circle {
  border-radius: 50%;
  border: 1px solid black;
  height: 15px;
  width: 15px;
}

.icnStyle {
  color: #000000;
  font-size: 2rem;
  cursor: pointer;
}

.formLable {
  font-size: 1.5rem;
  color: #545050;
  font-weight: 300;
}

.form-control, .form-select {
  padding: 0.6rem 0.9rem !important;
  border-radius: 14px !important;
}

.yesBtn {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  outline: none;
  box-shadow: none;
  padding: 0.5rem 1.5rem;
  border: none;
  color: white;
}

.noBtn {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  outline: none;
  box-shadow: none;
  padding: 0.5rem 1.5rem;
  border: none;
  color: white;
}

.congText {
  line-height: 3rem;
  border-radius: 1rem;
  color: rgb(72, 72, 72);
  padding: 1rem 1.5rem;
}

.accordion-button {
  font-weight: 500 !important;
}

.outerMoreDetails {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 98.06vw;
  height: 100vh;
  position: fixed;
  padding: 1rem;
  z-index: 3;
}

.innerMoreDetails {
  background-color: white;
  box-shadow: 0 0 5px rgb(102, 102, 102);
  padding: 1rem;
  border-radius: 1rem;
}

.loading-spinner {
  width: 80px;
  height: 80px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #fb2300;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
  width: 98.5vw;
  position: absolute;
  z-index: 10;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.tableLink {
  cursor: pointer;
  text-decoration: none;
  color: #fb2300;
  font-weight: 500;
}

.tableLink:hover {
  color: #cf2207;
}

.footerButton {
  cursor: pointer;
}

.phase {
  margin: 1rem;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 5rem;
  padding: 1rem 1.5rem;
  font-size: 1.1rem;
  line-height: 1.9rem;
  border: 1px solid black;
  border-radius: 2rem;
  box-shadow: 0 0 6px rgb(160, 160, 160);
}

.phaseToggle {
  margin: 1rem;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: .9rem 2rem;
  line-height: 1.5rem;
  border: 1px solid black;
  border-radius: 5rem;
  cursor: pointer;
}

.mobileSignIn{
  display: none;
}

@media only screen and (max-width : 990px) {
  .menuBar {
    padding: 2.5rem 5rem 0 1.5rem;
  }

  .mobileNone {
    display: none;
  }

  .signInGoogle {
    font-size: small;
    margin: 1.1rem .2rem 2.1rem .2rem;
  }

  .mobileSignIn{
    display: block;
  }
  .desktopSignIn{
    display: none;
  }

}



@media only screen and (max-width : 660px) {
  .points {
    padding: 0.8rem;
  }

  ul {
    font-size: 1rem;
    line-height: 2.3rem;
  }

  .formLable {
    font-size: 1.3rem;
    color: #000000;
    font-weight: 200;
  }

  .form-control, .form-select {
    font-size: 1rem;
  }

  .mainBtn {
    padding: 0.7rem 3rem;
    letter-spacing: 2px;
    background-color: #fb2300;
    transition: all ease-in-out .3s;
  }

  .congText {
    line-height: 2.2rem;
    border-radius: 1rem;
    font-size: 1rem;
    color: rgb(72, 72, 72);
    padding: 0rem 0rem;
  }

  .mainHeading2 {
    font-size: 1.2rem;
    color: #5D5C5C;
    font-family: 'Handlee', cursive;
  }

  .middleText {
    font-size: 1rem;
  }

  .btn {
    font-size: .8rem !important;
  }

  .hamburger {
    font-size: 1rem;
  }

  .menuBar {
    width: 100vw;
    text-align: center;
    padding: 2rem 1.7rem;
  }

  .menuBarCross {
    font-size: 2rem;
  }

  .registerBtn {
    padding: 1rem 5rem;
  }

  .accordion-button {
    font-size: .9rem !important;
  }

  .accordText {
    font-size: .8rem !important;
  }

  .footerText {
    font-size: 1.11rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .footerIcon{
    width: 72%;
  }

}